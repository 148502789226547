<template>
  <div>
    <!-- 头部 -->
    <section ref="refStop" class="section panel servicehome_header">
      <div class="col-lg-12 container_max container">
        <header class="header_title">
          <div class="T48px_Regular colorfff">
            <span class="color1D73FF">{{ $t("brand.title1") }}</span>
            <span>{{ $t("brand.title2") }}</span>
          </div>

          <div class="T18px_Regular colorfff header_title_tips">
            {{ $t("brand.header_title_tips") }}
          </div>
        </header>
      </div>
    </section>

    <!-- 步骤 -->
    <section ref="refStop" class="section step_box">
      <div
        class="flex-c step_box_max container"
        style="align-items: flex-start"
      >
        <div
          class="step_son_item flex-c panel_stop_box"
          v-for="(item, index) in stepArrList"
          :key="index"
        >
          <div
            class="flex-wb wow animate__fadeInUp"
            style="align-items: flex-end"
          >
            <span class="ranking T64px_Regular"> 0{{ index + 1 }} </span>
            <img class="step_son_max_logo" :src="item.url" alt="" />
          </div>

          <div class="flex-w step_son_item_title wow animate__fadeInUp">
            <!-- <img class="step_son_icons" :src="item.icons" alt="" /> -->
            <img class="step_son_icon" :src="item.icon" alt="" />
            <h1 class="flex-w T64px_Regular step_son_item_title_text">
              <span class="color1D73FF">{{ item.name }}</span>
              <span class="colorfff">{{ item.names }}</span>
            </h1>
          </div>
          <div
            class="T24px_Regular_ya step_son_text colorfff wow animate__fadeInUp"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </section>
    <!-- 项目总结 -->
    <section class="section panel project_summarize_box">
      <div class="container">
         <div class="customized_box" :class="$t('globalHeader.home') == 'Home' ? 'flex-w' : 'flex-wb'">
          <div class="flex-c">
            <span class="T24px_Regular colorfff">
              {{ $t("customized.title") }}
              <span class="color1D73FF">{{ $t("customized.title1") }}</span>
            </span>
            <span class="T16px_Regular color818181">
              {{ $t("customized.tipe") }}
            </span>
          </div>
          <div class="flex-c">
            <span v-show="$t('globalHeader.home') != 'Home'" class="T14px_Regular colorfff tips_net">
              {{ $t("customized.tipe_ri") }}
            </span>
            <span
              @click="routerBtn('/contact')"
              class="T20px_Regular colorfff my_btn animation_right"
            >
              {{ $t("customized.btn") }}
            </span>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, gsap);
gsap.registerPlugin(ScrollTrigger);

import { WOW } from "wowjs";

export default {
  data() {
    return {
      stepArrList: [
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max1.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max1.png",
          icons:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max1s.png",
          name: this.$t("brand.stepArrList1_name"),
          names: this.$t("brand.stepArrList1_names"),
          text: this.$t("brand.stepArrList1_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max2.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max2.png",
          icons:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max2s.png",
          name: this.$t("brand.stepArrList2_name"),
          names: this.$t("brand.stepArrList2_names"),
          text: this.$t("brand.stepArrList2_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max3.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max3.png",
          icons:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max3s.png",
          name: this.$t("brand.stepArrList3_name"),
          names: this.$t("brand.stepArrList3_names"),
          text: this.$t("brand.stepArrList3_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max4.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max4.png",
          icons:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max4s.png",
          name: this.$t("brand.stepArrList4_name"),
          names: this.$t("brand.stepArrListy4_names"),
          text: this.$t("brand.stepArrList4_text"),
        },
        {
          url: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/brand/l_max5.png",
          icon: "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max5.png",
          icons:
            "https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/service/l_max5s.png",
          name: this.$t("brand.stepArrList5_name"),
          names: this.$t("brand.stepArrList5_names"),
          text: this.$t("brand.stepArrList5_text"),
        },
      ],
    };
  },
  mounted() {
    // this.stopSlideInit();
    const wow = new WOW();
    wow.init();
  },
  methods: {
    /**
     *  步骤滚动动画
     */
    stopSlideInit() {
      var texts = gsap.utils.toArray(".panel_stop_box");

      // 默认选中第一个板块
      texts[0].classList.add("act");

      texts.forEach((text, i) => {
        gsap.timeline({
          scrollTrigger: {
            trigger: "section.step_box",
            scroller: this.$refs.scroller,
            start: () => "top -" + window.innerHeight * 0.3 * i,
            end: () => "+=" + window.innerHeight * 0.3,
            scrub: 1.5,
            snap: {
              snapTo: 1 / (texts.length - 1), // 自动捕捉到下一个区域
              duration: 0.5, // 动画的持续时间
              ease: "power2.inOut",
            },
            onEnter: () => {
              disableScroll(); // 禁用滚动
              text.classList.add("act");
              setTimeout(() => {
                enableScroll();
              }, 800);
            },
            onLeave: () => {
              if (i === texts.length - 1) {
                disableScroll(); // 禁用滚动
                text.classList.add("act");
                setTimeout(() => {
                  enableScroll();
                }, 800);
              } else {
                text.classList.remove("act");
              }
            },
            onEnterBack: () => {
              disableScroll(); // 禁用滚动
              text.classList.add("act");
              setTimeout(() => {
                enableScroll();
              }, 800);
            },
            onLeaveBack: () => {
              // text.classList.remove("act"); // 再次离开时移除 act 类
              if (i === 0) {
                disableScroll(); // 禁用滚动
                text.classList.add("act");
                setTimeout(() => {
                  enableScroll();
                }, 800);
              } else {
                text.classList.remove("act");
              }
            },
          },
        });
      });
      texts[0].classList.add("act");
      ScrollTrigger.create({
        trigger: "section.step_box",
        scroller: this.$refs.scroller,
        scrub: 1.5, // 禁用自动平滑过渡

        pin: true,
        start: "top top",
        end: () => "+=" + (texts.length + 1) * window.innerHeight * 0.3,
        onLeaveBack: () => {
          texts[0].classList.add("act"); // 确保在回到顶部时，第一个元素始终激活
        },
        // onLeave: () => {
        //     texts[texts.length - 1].classList.add("act"); // 滑动到底部时激活最后一个板块
        // }
      });

      // 延迟刷新，确保触发器的正确性
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 1500);
    },
    /**
     * 路由跳转方法
     * @param {*} id 文章id
     * @param {*} url 跳转路由
     */

    routerBtn(url, id) {
      if (id) {
        this.$router.push({ path: url, query: { id: id } });
        return;
      }
      this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.servicehome_header {
  // min-height: 100vh;
  background: #15171d;
  padding-top: 5.6875rem;
  padding-bottom: 0;

  .container_max {
    // width: 100%;
    // padding: 0 5.875rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .header_title {
      // width: 24.0625rem;
      margin-bottom: 1.6563rem;

      .header_title_tips {
        line-height: 1.3125rem;
        letter-spacing: 0.0313rem;
      }
    }
  }
}

.step_box {
  padding: 0;
  background: #15171d;
  // height: 30.6875rem;

  min-height: 100vh;

  .step_box_max {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .step_son_item {
    // min-width: 4.5438rem;
    // width: calc((100vw - 31.063rem) / 6);
    width: 100%;
    position: relative;
    min-height: 24.5313rem;
    top: 0;
    // opacity: 0.5;
    transition: all 0.8s ease;
    transform-origin: left; /* 从左侧开始扩展 */
    /* 动画从右侧展开 */
    overflow: hidden;

    .step_son_icon {
      width: 2.512rem;
      height: 2.512rem;
      // opacity: 0;
      // display: none;
      transition: all 0.8s ease;
      margin-right: 0.6024rem;
    }
    .ranking {
      // position: absolute;
      color: #fff;
      // top: 16.1563rem;
      // right: 0.75rem;
    }

    .step_son_max_logo {
      width: 17rem;
      height: 19.4375rem;
    }

    .step_son_item_title {
      margin-top: 2.5rem;
    }

    .step_son_text {
      line-height: 1.988rem;
      margin-top: 1.2048rem;
      transition: all 0.8s ease;
      margin-bottom: 4.6506rem;
    }
  }
}

.project_summarize_box {
  padding: 2rem 0 3rem 0;
  background: #15171d;

  // padding-top: 2.75rem;

  .customized_box {
    margin-top: 1.525rem;
    min-height: 7.9375rem;
    padding: 1.2rem 0.9813rem 1.6rem 0.9188rem;
    background-image: url("https://blackwaves-1329498277.cos.ap-shanghai.myqcloud.com/images/home/dingzhi.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    // display: inline-block;
    .T36px_Regular {
      font-size: 1rem;
    }
    .tips_net {
      height: 0.8438rem;
    }

    .my_btn {
      width: 5.8125rem;
      height: 1.7813rem;
      border: 0.0313rem solid #ffffff;
      text-align: center;
      line-height: 1.7813rem;
    }
  }
}
</style>
